<nav class="navbar navbar-expand navbar-dark">
  <a class="hwlogo" href="https://www.hutchisonweller.com/" target="_blank">
    <img src="/assets/knownoise-logo.svg" />
  </a>
  <a class="navbar-brand" routerLink="/"><b>KNOW</b>Noise</a>

  <div class="flex-grow-1"></div>

  <ng-container *ngIf="currentUser">
    <ul class="navbar-nav ms-auto">
      <li class="nav-item dropdown">
        <a
          id="navbarDropdown"
          href
          data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle"
          (click)="false">
          {{ currentUser.email }}
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <ng-container *appShowIfAdmin>
            <a class="dropdown-item" [routerLink]='["./admin/manage-projects"]'>Manage projects</a>
            <a class="dropdown-item" [routerLink]='["./admin/manage-minorworks"]'
              >Manage Minor Works</a
            >
            <a class="dropdown-item" [routerLink]='["./admin/manage-permissions"]'
              >Manage permissions</a
            >
            <a class="dropdown-item" [routerLink]='["./admin/manage-equipment"]'
              >Manage equipment</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]='["./admin/user-manuals"]'>User manuals</a>
            <div class="dropdown-divider"></div>
          </ng-container>
          <a class="dropdown-item" [routerLink]='["./logout"]'>Log out</a>
        </div>
      </li>
    </ul>
  </ng-container>
</nav>
