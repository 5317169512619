import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppState, getCurrentUser } from './state';
import { AppSettings } from './app.generated';
import { AppSettingsBootstrapService, AuthService, ScriptLoaderService } from './services';
import { Script } from './models';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  appSettings: AppSettings;
  appInsights: ApplicationInsights;

  currentUser$ = this.store.pipe(select(getCurrentUser));

  constructor(
    private readonly store: Store<AppState>,
    private readonly scriptLoader: ScriptLoaderService,
    private readonly router: Router,
    auth: AuthService,
    appSettingsService: AppSettingsBootstrapService
  ) {
    this.appSettings = appSettingsService.appSettings;
    auth.handleAuthentication();
    auth.scheduleRenewal();
  }

  ngOnInit(): void {
    this.scriptLoader
      .load(<Script>{
        name: 'google-maps',
        src: `https://maps.googleapis.com/maps/api/js?key=${this.appSettings.googleMapsApiKey}&loading=async&libraries=geometry,places,marker`
      })
      .pipe(untilDestroyed(this))
      .subscribe((s) => console.log(`Loaded Google Maps`));

    if (this.appSettings.applicationInsightsConnectionString) {
      const angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.appSettings.applicationInsightsConnectionString,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  isProduction(): boolean {
    return this.appSettings.deploymentEnvironmentName.toUpperCase() === 'Production'.toUpperCase();
  }
}
