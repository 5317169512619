import { Component, Input } from '@angular/core';

import * as fromServices from '../services';
import * as fromModels from '../models';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  UserInfoService = fromServices.UserInfoService;

  @Input()
  currentUser: fromModels.UserVMEx;

  navbarOpen = false;
}
