import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';

import * as fromRootStore from './state';
import { environment } from '../environments/environment.prod';

import * as fromGenerated from './app.generated';
import * as fromGuards from './guards';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import * as fromServices from './services';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AppRoutingModule } from './app-routing.module';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@NgModule({ declarations: [AppComponent, NavMenuComponent],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        AppRoutingModule,
        RouterModule,
        ConfirmDialogModule,
        ToastModule,
        StoreModule.forRoot(fromRootStore.reducers),
        EffectsModule.forRoot(fromRootStore.effects),
        StoreModule.forFeature('appState', fromRootStore.reducers),
        StoreDevtoolsModule.instrument({
            name: environment.productName,
            logOnly: environment.production,
            connectInZone: true
        }),
        StoreRouterConnectingModule.forRoot({
            routerState: RouterState.Full,
            serializer: fromRootStore.CustomSerializer
        }),
        SharedModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        FontAwesomeModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (s: fromServices.AppSettingsBootstrapService) => () => s.loadAppSettings(),
            multi: true,
            deps: [fromServices.AppSettingsBootstrapService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: fromServices.TokenHttpInterceptor,
            multi: true
        },
        ...fromServices.services,
        ...fromGuards.guards,
        fromGenerated.AppSettingsService,
        fromGenerated.EquipmentsService,
        fromGenerated.GroundBorneEquipmentsService,
        fromGenerated.ProjectsService,
        fromGenerated.BuildingsService,
        fromGenerated.AssessmentsService,
        fromGenerated.ActivitiesService,
        fromGenerated.UsersService,
        fromGenerated.CurrentUserService,
        fromGenerated.UserManualsService,
        fromGenerated.EquipmentsCategoriesService,
        ConfirmationService,
        MessageService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
