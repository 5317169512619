import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as fromGuards from './guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [fromGuards.AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [fromGuards.AuthGuard],
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [fromGuards.AuthGuard],
  },
  {
    path: 'minor-works',
    loadChildren: () => import('./minor-works/minor-works.module').then(m => m.MinorWorksModule),
    canActivate: [fromGuards.AuthGuard],
  },
  {
    path: 'ground-borne',
    loadChildren: () => import('./ground-borne/ground-borne.module').then(m => m.GroundBorneModule),
    canActivate: [fromGuards.AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
