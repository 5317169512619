<div class="environmentMarker" *ngIf="!isProduction()">
  {{ appSettings.deploymentEnvironmentName }}
</div>

<app-nav-menu [currentUser]="currentUser$ | async"></app-nav-menu>

<div role="main">
  <router-outlet></router-outlet>
</div>

<ngx-loading-bar [includeSpinner]="false" height="4px" color="#00B0F0"></ngx-loading-bar>
<p-confirmDialog></p-confirmDialog>
<p-toast [baseZIndex]="5000"></p-toast>
